<template>
  <v-container v-if="flight.pools.length === 0" class="pt-0">
    <message-card>
      <h2>The pools have not been filled yet.</h2>
    </message-card>
  </v-container>
  <v-container fluid :class="{'px-0': $vuetify.breakpoint.xs, 'pt-0': true || (!round.hideTimes && startTime)}" v-else>
    <v-row dense v-if="(!round.hideTimes || tournament.showStartTimes) && startTime && !embeded && !showPoolStartTimes">
      <v-col cols="12" class="pa-0">
        <v-alert type="info" :value="true" class="black--text ma-0" prominent text>
          <strong>Play starts {{startTime}}</strong>
        </v-alert>
      </v-col>
    </v-row>
    <!-- Admin Buttons -->
    <v-row dense v-if="view.admin && !tournament.locked && !round.locked && !embeded" justify="space-between" class="px-2">
        <rep-field-dialog :division="division" v-if="!isLeague"></rep-field-dialog>
        <v-btn
          v-if="!editing"
          small
          color="color3"
          text
          class="ma-0 pa-0"
          style="font-size: small"
          @click.stop="preEdit"
        >Edit pools</v-btn>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="pt-0">
        <v-card color="grey lighten-4">
          <v-container fluid>
            <v-row dense align="center">
              <!-- Division Name -->
              <v-col class="headline black--text pb-1" v-if="!isLeague">
                {{division.name}} {{round.name}} <span v-if="round.waitingOn" class="grey--text caption"> waiting on {{round.waitingOn}}</span><v-icon small v-if="round.flight && round.flight.complete">far fa-check fa-fw</v-icon>
                <div class="mt-n3" v-if="!embeded">
                  <v-btn
                    color="color3"
                    text
                    x-small
                    @click.stop="mini = !mini"
                  >{{mini ? 'expand' : 'minfy'}}</v-btn>
                </div>
              </v-col>
              <v-col class="text-right">
                <!-- Add Pool -->
                <v-btn
                  v-if="editing"
                  small
                  color="color3"
                  text
                  class="mt-0 mr-0"
                  style="font-size: small"
                  @click.stop="addPool"
                >
                  Add Pool
                </v-btn>
                <v-fab-transition>
                  <forfeit-wizard
                    btn-class="mr-1"
                    :round="round"
                    v-if="!editing && view.adminAndRefs && !tournament.locked && !round.locked && !isLeague"
                  ></forfeit-wizard>
                </v-fab-transition>
                <!-- Print -->
                <pdf-maker
                  :round="round"
                  :pools="round.pools"
                  :tournament="tournament"
                  :list="!cardDisplay"
                  v-if="(customPrint || !cardDisplay) && !editing && view.adminAndRefs && !isLeague"
                  btn-class="mr-1"
                ></pdf-maker>
                <v-btn
                  color="color3Text color3--text"
                  class="pa-0 mr-1"
                  fab
                  small
                  @click.stop="print"
                  v-if="(!customPrint && cardDisplay) && !editing && view.adminAndRefs && !isLeague"
                >
                  <v-icon small>fas fa-print</v-icon>
                </v-btn>
                <!-- View Toggle-->
                <v-fab-transition>
                  <v-btn
                    fab
                    small
                    color="color3Text color3--text"
                    @click.stop="cardDisplay = !cardDisplay"
                    v-if="!editing && !isLeague"
                  >
                    <v-icon small>{{activeFab}}</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
            <!-- Card View -->
            <template v-if="cardDisplay">
              <v-row dense v-if="view.admin && !embeded && !isLeague">
                <v-col class="d-flex align-center" :class="{ 'justify-start': !editing, 'justify-end': editing }" cols="12" >
                  <div v-if="!(editing | interweaving)" class="d-flex">
                    <v-checkbox :label="`Unlocked Pools Only${unlockedPoolsText}`" color="success" v-model="unlockedOnly" hide-details class="mt-0 mr-3 shrink"></v-checkbox>
                    <v-checkbox :label="`Ready Pools Only${readyPoolsText}`" color="success" v-model="readyOnly" hide-details class="mt-0 mr-3 shrink"></v-checkbox>
                    <v-checkbox label="Hide Live Scores" color="success" v-model="nolive" hide-details class="mt-0 shrink"></v-checkbox>
                  </div>
                  <div v-else>
                    <add-courts-dialog
                      :tournament="tournament"
                      :emit="true"
                      :noSort="true"
                      @emit-courts="onEmitCourts"
                    ></add-courts-dialog>
                    <interweave-dialog
                      :flight="flight"
                      v-if="(editing | interweaving) && flight.pools.length > 1 && !tournament.locked && !round.locked"
                      @loading-change="onInterweavingChange"
                    ></interweave-dialog>
                  </div>
                </v-col>
              </v-row>
              <v-row dense :class="{'hidden': !editing}">
                <v-col cols="12" sm="6" xl="4">
                  <pool-card
                    :pool="pool0"
                    :sorting="sorting"
                    :editing="editing"
                    :ref="`pool_0`"
                    :holder="true"
                    :key="`pool_0_${poolLessTeams.length}`"
                  ></pool-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" xl="4"
                  v-for="(pool, i) in flight.pools"
                  :key="`pool_${i + 1}`"
                  :id="`pool_${i + 1}_${pool.locked}`"
                  :class="{'hidden': (pool.locked && (unlockedOnly || _unlockedOnly)) || (!(pool.complete && !pool.locked) && (readyOnly || _readyOnly))}"
                >
                  <pool-card
                    :pool="pool"
                    :sorting="sorting"
                    :editing="editing"
                    :ref="`pool_${i + 1}`"
                    :key="`pool_${i + 1}_${pool.teams.length}`"
                    :lowlight="highlight && highlight.poolId !== pool.id"
                    :highlight="highlight && highlight.id"
                    :showStartTime="!editing && !sorting && (!round.hideTimes || tournament.showStartTimes) && !embeded && (showPoolStartTimes  || tournament.showStartTimes)"
                    :nolive="nolive || _nolive"
                    :mini="mini || _mini"
                    :isLeague="isLeague"
                  ></pool-card>
                </v-col>
              </v-row>
              <table id="append">
                <tbody></tbody>
              </table>
            </template>
            <!-- List View -->
            <template v-else>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    append-icon="fas fa-search"
                    label="Search"
                    hide-details
                    color="color3"
                    v-model="search"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-data-table
                    :items="tableViewItems"
                    :headers="headers"
                    :loading="division.loading"
                    :options.sync="page"
                    :search="search"
                    class="elevation-1"
                    hide-default-footer
                  >
                    <template v-slot:[`item.rank`]="{ item }">
                      {{item.rank | ordinal}}
                    </template>
                    <template v-slot:[`item.repping`]="{ item }">
                    <flag
                      v-if="item.countryCode"
                      :iso="item.countryCode"
                      :squared="false"
                    />
                      {{item.repping}}
                    </template>
                    <template v-slot:[`item.courts`]="{ item }">
                      {{item.courts | formatArray}}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn
                        color="color3 color3Text--text"
                        small
                        fab
                        :to="{ name: 'pool-sheet', params: { poolId: item.poolId } }"
                      >
                        <v-icon>fas fa-caret-right</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </template>
          </v-container>
        </v-card>
        <div class="caption" v-if="view.admin && !embeded && !isLeague">
          <v-btn
            color="color3"
            x-small text
            @click.stop="clearCourts = true"
            :loading="clearCourts"
          >
            clear Courts
          </v-btn>
          <vbl-confirm-dialog
            :ask="clearCourts"
            @deny="clearCourts = false"
            @confirm="onClearConfirm"
            :loading="loading"
          ></vbl-confirm-dialog>
        </div>
        <div class="caption" v-if="user && user.vbl">Flight Id: {{flight.id}}</div>
      </v-col>
    </v-row>

    <!-- FABs -->
    <flight-search
      v-if="!noSearch"
      :flight="flight"
      @highlight="onHighlight"
    ></flight-search>
    <v-btn
      v-if="editing && dirty"
      fab
      bottom
      right
      fixed
      style="margin-right: 60px"
      color="color3 color3Text--text"
      @click.stop="saveChanges"
    >
      <v-icon>fas fa-save</v-icon>
    </v-btn>
    <v-btn
      v-if="editing || sorting"
      fab
      bottom
      right
      fixed
      color="red white--text"
      @click.stop="cancel"
    >
      <v-icon>fas fa-times</v-icon>
    </v-btn>

    <!-- EDIT WARNING DIALOG -->
    <v-dialog
      v-model="warn"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar color="warning black--text">
          <v-icon>fas fa-exclamation</v-icon>
          <v-toolbar-title>Warning</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <h3>Editing pools now could cause a loss of data</h3>
          <div class="pt-2">You have manually edited a match by recording the score or updating the start time, court or ref.</div>
          <div class="pt-2">Any changes to the teams in {{dbMatches2.length === 1 ? 'pool' : 'pools'}} {{dbMatches2 | formatArray}} could result in losing those updates.</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="color3" text @click.stop="edit">OK</v-btn>
          <v-btn color="error" text @click.stop="warn = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import Pool from '@/classes/Pool'
import PoolTeam from '@/classes/PoolTeam'
import PoolCard from '@/components/Tournament/Pools/PoolCard.vue'
import { Sortable } from '@shopify/draggable'
import firstBy from 'thenby'
import * as mutations from '@/store/MutationTypes'
import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'
import moment from 'moment'
const RepFieldDialog = () => import('@/components/Tournament/RepFieldDialog.vue')
const ForfeitWizard = () => import('@/components/Tournament/ForfeitWizard.vue')
const InterweaveDialog = () => import('./InterweaveDialog')
const PdfMaker = () => import('@/components/Tournament/Pools/PdfMaker.vue')
const FlightSearch = () => import('./FlightSearch')
const AddCourtsDialog = () => import('@/components/Tournament/Courts/AddCourtsDialog')

export default {
  mixins: [RouteMixin],
  props: ['flight', 'noSearch', '_readyOnly', '_unlockedOnly', 'embeded', '_nolive', '_mini', 'isLeague'],
  data () {
    return {
      display: 'card',
      page: { itemsPerPage: -1 },
      search: null,
      sortable: null,
      editing: false,
      pool0: new Pool(this.$VBL, {
        holder: true,
        name: 'Pooless Teams'
      }),
      cardDisplay: true,
      unlockedOnly: false,
      readyOnly: false,
      nolive: false,
      mini: false,
      warn: false,
      timer: null,
      highlight: null,
      interweaving: false,
      clearCourts: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['view', 'tournament', 'getDivision', 'getRound', 'getTeam', 'user']),
    customPrint () {
      return this.tournament.props.includes('customPrint') || this.division.props.includes('customPrint')
    },
    dbMatches () {
      return flatten(this.flight.pools.map(pool => {
        return pool.matches
      })).filter(f => f.id > 0)
    },
    dbMatches2 () {
      return this.flight.pools.filter(f => f.matches.find(m => m.id > 0)).map(p => p.name)
    },
    startTime () {
      if (!this.poolStartTimes.length) return null
      const dtStart = moment(this.poolStartTimes[0])
      const dtStartL = moment(dtStart.format().replace('Z', this.division.location.offset))
      if (dtStartL.isBefore()) return null
      const t = dtStart.format('h:mm A')
      const d = dtStart.format('ddd h:mm A')
      return this.tournament.isOneDay ? t : d
    },
    poolStartTimes () {
      const times = this.flight.pools && this.flight.pools.map(m => m.startTimeCalc).filter(f => !!f)
      return times ? times.sort((a, b) => a.unix() - b.unix()) : []
    },
    poolMatchTimes () {
      return this.flight.pools.map(m => m.matchTimes)
    },
    showPoolStartTimes () {
      const s = this.poolStartTimes && new Set(this.poolStartTimes.map(m => `${m.unix()}`))
      return s.size > 1
    },
    activeFab () {
      return this.cardDisplay ? 'fas fa-list' : 'fas fa-table'
    },
    division () {
      return this.divisionId ? this.getDivision(this.divisionId) : this.tournament.divisions.find(f => f.flights.find(f => f.id === this.flight.id))
    },
    round () {
      return this.dayId ? this.getRound(this.dayId) : this.tournament.rounds.find(f => f.flights.find(f => f.id === this.flight.id))
    },
    sorting () {
      return !!this.sortable
    },
    dirty () {
      return this.dirtyPools && this.dirtyPools.length > 0
    },
    dirtyTeams () {
      if (this.flight.dirty) {
        return this.flight.dirtyTeams.map(t => t.dto)
      }
      return null
    },
    dirtyPools () {
      return this.flight.dirtyPools && this.flight.dirtyPools.map(p => p.dto)
    },
    unlockedPools () {
      return this.flight.pools.filter(f => !f.locked)
    },
    unlockedPoolsText () {
      const n = this.unlockedPools.length
      return n > 0 ? ` (${n})` : ''
    },
    readyPools () {
      return this.flight.pools.filter(f => f.complete && !f.locked)
    },
    readyPoolsText () {
      const n = this.readyPools.length
      return n > 0 ? ` (${n})` : ''
    },
    poolTeams () {
      return this.round.poolTeams
    },
    poolLessTeams () {
      return (this.division && this.division.poollessTeamIds) || []
    },
    showPool0 () {
      return !!this.round.teamSelectors.find(f => f.seedStyle === 'ORIGINAL_SEED' && f.type === 'ALL')
    },
    tableViewItems () {
      var teams = flatten(this.flight.pools.map(pool => {
        return pool.teams.map(team => {
          const t = this.getTeam(team.teamId)
          var e = pool.locked && !team.summary
          return {
            pool: pool.name,
            poolId: pool.id,
            team: t.name,
            players: t.players,
            teamId: team.teamId,
            repping: t.getRep(this.division.repField),
            countryCode: t.getCountryCode(),
            slot: team.slot,
            courts: pool.courts,
            rank: e ? 'NA' : pool.locked ? team.summary.finish : t.rank
          }
        })
      }))

      return teams
    },
    headers () {
      var base = [
        { text: 'Team', value: 'team', align: 'left', sortable: true },
        { text: 'Representing', value: 'repping', align: 'left', sortable: true },
        { text: 'Pool', value: 'pool', align: 'center', sortable: true },
        { text: 'Standing', value: 'rank', align: 'center', sortable: true },
        { text: 'Court', value: 'courts', align: 'center', sortable: true },
        { value: 'actions', align: 'right', sortable: false }
      ]

      if (this.$vuetify.breakpoint.xs) base.splice(1, 1)
      return base
    },
    noRep () {
      return this.division && this.division.props.includes('noRep')
    }
  },
  methods: {
    onInterweavingChange (val) {
      this.interweaving = val
      if (val) {
        this.cancel()
      }
    },
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    bTv () {
      var teams = this.division.teams.map(t => {
        return {
          id: t.id,
          name: t.name,
          players: t.players.map(p => {
            return {
              id: p.playerProfileId,
              name: p.name
            }
          })
        }
      })
      return teams
    },
    bTv2 () {
      var matches = flatten(this.flight.pools.map(pool => {
        return pool.matches.map(m => {
          return {
            id: m.id,
            title: `${m.homeTeam.name} vs ${m.awayTeam.name}`,
            description: `Pool ${pool.name} Match ${m.number}`,
            division: this.division.name,
            divisionId: this.division.id,
            home_id: m.homeTeam.teamId,
            away_id: m.awayTeam.teamId,
            court: m.court,
            // date_time: moment(m._startTime.replace('Z', this.division.location.offset)).format(),
            date_time: m.startTime.utcOffset(this.division.location.offset).format()
          }
        })
      }))
      return matches
    },
    print () {
      window.print()
      return false
    },
    addPool () {
      this.flight.addPool()
      this.$nextTick(() => {
        this.makeSortable()
      })
    },
    preEdit () {
      if (this.dbMatches.length) {
        this.warn = true
        return
      }
      this.edit()
    },
    edit () {
      this.warn = false
      this.display = 'card'
      // this.initPoolless()
      this.round.edit()
      this.flight.edit()
      this.editing = true
      this.$nextTick(() => {
        this.makeSortable()
      })
    },
    initPoolless () {
      this.pool0.teams = this.round.poollessTeamIds.map(id => {
        const t = this.getTeam(id)
        return new PoolTeam(this.$VBL, {
          name: t.name,
          teamId: t.id,
          repping: t.getRep(this.division.repField)
        })
      })
    },
    sort () {
      this.flight.pools.sort(firstBy('id'))

      this.flight.pools.forEach(pool => {
        pool.teams.sort(firstBy('slot'))
      })
    },
    cancel () {
      console.log('here')
      this.flight.restore()
      this.round.restore()
      if (this.sortable) {
        this.sortable.destroy()
        this.sortable = null
      }
      this.editing = false
    },
    saveChanges () {
      if (this.dirty) {
        const dto = [this.flight.dto]
        this.$store.commit(mutations.LOADING, 'PoolReviewDirtyTeams')
        this.$VBL.patch.flights(dto)
          .then(() => {
            this.sortable.destroy()
            this.sortable = null
            this.editing = false
          })
          .catch(err => {
            console.log(err.response)
          })
          .finally(() => {
            this.$store.commit(mutations.LOADING_COMPLETE, 'PoolReviewDirtyTeams')
          })
      }
    },
    makeSortable () {
      const pools = document.querySelectorAll('.pool')
      if (pools.length === 0) {
        console.log('no pools')
        return
      }
      if (this.sortable) {
        this.sortable.destroy()
      }
      const sortable = new Sortable(pools, {
        draggable: '.team',
        dropzone: '.pool',
        handle: '.sortHandle',
        mirror: {
          constrainDimensions: true,
          appendTo: '#append'
        }
      })

      sortable.on('sortable:stop', (e) => {
        const newPool = e.newContainer.__vue__.$parent.$parent
        const oldPool = e.oldContainer.__vue__.$parent.$parent
        const team = oldPool.remove(e.oldIndex)

        newPool.add(e.newIndex, team)
        this.$nextTick(() => {
          this.makeSortable()
        })
      })
      this.sortable = sortable
    },
    onHighlight (team) {
      if (this.timer) clearTimeout(this.timer)
      this.highlight = team
      const i = this.flight.pools.findIndex(f => f.id === team.poolId)
      const target = this.$refs[`pool_${i + 1}`]
      if (target) {
        const t = target[0]
        if (t) {
          this.$vuetify.goTo(t, {
            duration: 333,
            offset: 133,
            easing: 'easeInOutCubic'
          })
        }
      }
      this.timer = setTimeout(() => {
        this.highlight = null
      }, 5000)
    },
    onEmitCourts (courts) {
      const p = this.flight.pools
      const n = p.length < courts.length ? p.length : courts.length
      for (let i = 0; i < n; i++) {
        p[i].courts = courts[i].replace('|', ',')
      }
    },
    onClearConfirm () {
      this.loading = true
      this.$VBL.tournament.clearCourts(this.flight.id)
        .then(r => { this.clearCourts = false })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    'division.poollessTeamIds': function (n, o) {
      if ((n && n.length) || (o && o.length)) {
        this.pool0.teams = []
        n.forEach(id => {
          const t = this.getTeam(id)
          this.pool0.teams.push(new PoolTeam(this.$VBL, {
            name: t.name,
            teamId: t.id,
            repping: t.getRep(this.division.repField)
          }))
        })
        this.pool0.teams.sort(firstBy('name'))
      }
    },
    editing: function (val) {
      this.$emit('edit-change', val)
    }
  },
  components: {
    PoolCard,
    RepFieldDialog,
    PdfMaker,
    ForfeitWizard,
    InterweaveDialog,
    FlightSearch,
    AddCourtsDialog
  },
  mounted () {
    this.flight.pools.sort(firstBy('number'))
  }
}
</script>

<style scoped>
.hidden {
  display: none;
}
</style>
